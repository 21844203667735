<template>
    <div
        class="chat_actions"
        :class="{'chat_actions--ticket':showTwoBtn}"
    >
        <div class="typing_message">
            <slot />
        </div>
        <div class="chat_actions_form">
            <form-textarea
                v-model="message"
                class="form-textarea__input-messages"
                legend="Message"
                type="text"
                name="message"
                :disabled="isLoading"
                :field.sync="message"
                :errors="validationMessage($v.message)"
                @input.native="$v.message.$touch()"
                @blur.native="onBlur"
                @focus.native="onFocus"
                @textareaBlur="onBlur"
                @textareaFocus="onFocus"
                @keyup.native="keyListener($event)"
            />
            <div class="chat_actions__buttons">
                <custom-button
                    class="btn-base btn-main chat-actions-btn btn-reply"
                    :loading="isLoading"
                    @on-btn-click="sendMessage()"
                >
                    <replyIcon v-if="!isLoading" />
                </custom-button>
                <custom-button
                    v-if="status === 'OPEN' && isBtnShow"
                    class="btn-base btn-border chat-actions-btn btn-issue-resolved"
                    :loading="isLoading"
                    @on-btn-click="openConfirmModal('Close ticket', 'Are you really want to close the ticket ?')"
                >
                    My issue is resolved. Close ticket
                </custom-button>
            </div>
        </div>

        <confirm-modal ref="confirm-modal" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { debounce } from 'lodash'

// Validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { required } from 'vuelidate/lib/validators'
import { formMessages } from '@/validation/support/Messages'
// Components
import replyIcon from '@/components/icons/reply.vue'
import ConfirmModal from '@/components/modals/ConfirmModal';

import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'

export default {
    components: {
        replyIcon,
        ConfirmModal
    },
    mixins: [
        validationMixin
    ],
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        lastMessage: {
            type: Object
        },
        status: {
            type: String
        },
        ticketId: {
            type: String
        }
    },
    validations: {
        message: { required }
    },
    data() {
        return {
            message: '',
            isBtnShow: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ]),
        showTwoBtn() {
            return this.ticketId && this.status === 'OPEN'
        }
    },
    watch: {
        lastMessage() {
            this.message = ''
            this.$v.$reset()
        }
        // message() {
        //     this.$emit('typing', true)
        //     this.userTyping(this)
        // }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        sendMessage() {
            this.$v.$touch()
            if (this.$v.$invalid) return
            this.$emit('sendMessage', { message: this.message })
            if (this.status === 'CLOSED' || this.isBtnShow === false) {
                eventBus.$emit('ChangeStatusOpen', this.ticketId)
                this.isBtnShow = true
            }
        },
        // userTyping: debounce((self) => {
        //     self.$emit('typing', false)
        // }, 1000),
        keyListener(e) {
            if (e.ctrlKey && e.keyCode === 13) {
                this.message += '\n'
                return
            }
            if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
                e.preventDefault();
                if (!this.getterIsAuth) {
                    this.$refs.recaptcha.buttonClick()
                    return
                }
                this.sendMessage()
            }
        },
        closeTicket() {
            Api.put('/api/support/auth/close-ticket', {
                id: this.ticketId
            })
                .then(() => {
                    eventBus.$emit('ChangeStatusClosed', this.ticketId)
                    eventBus.$emit('showSnackBar', 'Your ticket is closed!', 'success')
                    this.isBtnShow = false
                })
                .catch(() => {
                    eventBus.$emit('showSnackBar', 'Error', 'error');
                })
        },
        openConfirmModal(title, message) {
            this.$refs['confirm-modal'].open(title, message)
                .then((res) => {
                    if (res) {
                        this.closeTicket()
                    }
                })
        },
        onBlur() {
            this.$emit('typing', false)
            this.$v.message.$touch()
        },
        onFocus() {
            this.$emit('typing', true)
        }
    }
}
</script>

<style lang="scss">
    .chat_actions{
        display: flex;
        flex-direction: column;
        &_form {
            width: 100%;
            display: flex;
        }
        &__buttons {
            display: flex;
        }
        &--ticket {
            @media all and (max-width: 768px) {
                .chat_actions_form{
                    flex-direction: column;
                }
                .chat_actions__buttons {
                    flex-direction: column;
                    & > button {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    .btn-reply {
                        width: 100%;
                        max-width: 100%;
                        margin: 10px 0;
                    }
                }
            }
        }
    }
    .btn-reply{
        margin-bottom: 2px;
        width: 85px;
        min-width: 85px;
        max-width: 85px;
        height: 66px;
    }
    .btn-issue-resolved{
        height: 66px;
        margin-bottom: 2px;
    }
    .typing_message {
        height: 24px;
        line-height: 1;
        margin-bottom: 10px;
        width: 100%;
        font-size: 14px;
        font-style: italic;
    }
</style>
